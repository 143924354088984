<template>
  <fragment>
    <v-row
      v-if="isRegisterForm"
      justify-md="end"
      justify-sm="center"
      :class="$vuetify.breakpoint.mdAndUp ? 'mr-4 mt-2' : 'ml-8 mt-2'"
    >
      <v-btn v-if="!isSame" small color="warning" @click="fillParentAddress"
        >Kendi Adres Bilgilerimi Kullan</v-btn
      >
      <v-btn v-else small color="error" @click="dropParentAddress">
        Kendi Adres Bilgilerimi Kullanmayı Bırak
      </v-btn>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12">
        <v-autocomplete
          v-model="user.parent_information.address.country_id"
          :items="countries"
          item-value="value"
          item-text="text"
          label="Ülke"
          hide-details="auto"
          :rules="[(v) => !!v || 'Ülke alanı boş bırakılamaz']"
          required
        />
      </v-col>
    </v-row>
    <fragment v-if="user.parent_information.address.country_id === 222">
      <v-row>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="user.parent_information.address.city_id"
            :items="cities"
            item-value="value"
            item-text="text"
            label="İl"
            hide-details="auto"
            @change="getDistricts($event)"
            :rules="[(v) => !!v || 'İl alanı boş bırakılamaz']"
            required
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="user.parent_information.address.district_id"
            :items="districts"
            label="İlçe"
            :loading="citiesLoading"
            item-value="value"
            item-text="text"
            hide-details="auto"
            :rules="[(v) => !!v || 'İlçe alanı boş bırakılamaz']"
            required
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="user.parent_information.address.neighborhood"
            label="Mahalle"
            hide-details="auto"
            :rules="[(v) => !!v || 'Mahalle alanı boş bırakılamaz']"
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="user.parent_information.address.street"
            label="Cadde - Sokak"
            hide-details="auto"
            :rules="[(v) => !!v || 'Sokak alanı boş bırakılamaz']"
            required
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="user.parent_information.address.building"
            label="Bina Numarası"
            hide-details="auto"
            :rules="[(v) => !!v || 'Bina numarası alanı boş bırakılamaz']"
            required
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="user.parent_information.address.door"
            label="Kapı Numarası"
            hide-details="auto"
            :rules="[(v) => !!v || 'Kapı numarası alanı boş bırakılamaz']"
            required
          />
        </v-col>
      </v-row>
    </fragment>
    <fragment v-else>
      <v-text-field
        class="mt-6"
        v-model="user.parent_information.address.address_line"
        label="Açık Adres"
        hide-details="auto"
        :rules="[(v) => !!v || 'Açık adres alanı boş bırakılamaz']"
        required
      />
    </fragment>
  </fragment>
</template>

<script>
import AxiosInstance from "@/core/services/axios.service";

export default {
  name: "Address",
  props: {
    user: {
      type: Object,
    },
    isFormValid: {
      type: Boolean,
      default: true,
    },
    isRegisterForm: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "user",
    event: "update:user",
  },
  mounted() {
    this.getCountries().then((countries) => {
      this.countries = countries;
      this.user.parent_information.address.country_id =
        this.user.parent_information.address.country_id || 222;
    });
    this.getCities().then((cities) => {
      this.cities = cities;
      this.user.parent_information.address.city_id =
        this.user.parent_information.address.city_id || null;
      if (this.user.parent_information.address.city_id) {
        this.getDistricts(this.user.parent_information.address.city_id).then(
          (districts) => {
            this.districts = districts;
            this.user.parent_information.address.district_id =
              this.user.parent_information.address.district_id || null;
          }
        );
      }
    });
  },
  data() {
    return {
      countries: [],
      cities: [],
      districts: [],
      citiesLoading: false,
      isSame: false,
    };
  },
  methods: {
    fillParentAddress() {
      this.isSame = !this.isSame;
      this.user.parent_information.address.country_id =
        this.user.address.country_id;
      this.user.parent_information.address.city_id = this.user.address.city_id;
      this.citiesLoading = true;
      this.getDistricts(this.user.address.city_id).then((districts) => {
        this.districts = districts;
        this.user.parent_information.address.district_id =
          this.user.address.district_id;
        this.citiesLoading = false;
      });
      this.user.parent_information.address.neighborhood =
        this.user.address.neighborhood;
      this.user.parent_information.address.street = this.user.address.street;
      this.user.parent_information.address.building =
        this.user.address.building;
      this.user.parent_information.address.door = this.user.address.door;
      this.user.parent_information.address.address_line =
        this.user.address.address_line;
    },
    dropParentAddress() {
      this.isSame = !this.isSame;
      this.user.parent_information.address.country_id = null;
      this.user.parent_information.address.city_id = null;
      this.user.parent_information.address.district_id = null;
      this.user.parent_information.address.neighborhood = null;
      this.user.parent_information.address.street = null;
      this.user.parent_information.address.building = null;
      this.user.parent_information.address.door = null;
      this.user.parent_information.address.address_line = null;
    },
    getCountries() {
      return new Promise((resolve) => {
        AxiosInstance.get("/locations/countries").then((response) => {
          resolve(
            response.data.map((country) => {
              return {
                text: country.name,
                value: country.id,
                code: country.code,
              };
            })
          );
        });
      });
    },
    getCities() {
      return new Promise((resolve) => {
        AxiosInstance.get("/locations/cities").then((response) => {
          resolve(
            response.data.map((city) => {
              return {
                text: city.name,
                value: city.id,
                key: city.city_key,
              };
            })
          );
        });
      });
    },
    getDistricts(city_id) {
      this.citiesLoading = true;
      const city = this.cities.find((city) => city.value === city_id);
      return new Promise((resolve) => {
        AxiosInstance.get(`/locations/districts/${city.key}`)
          .then((response) => {
            const result = response.data.map((district) => {
              return {
                text: district.name,
                value: district.id,
              };
            });
            this.districts = result;
            resolve(result);
          })
          .finally(() => {
            this.citiesLoading = false;
          });
      });
    },
  },
  watch: {
    user: {
      handler: function (val) {
        this.$emit("update:user", val);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
h2,
p {
  display: inline-block;
  margin: 0;
  padding: 0;
}
p {
  color: #ff5252;
  font-style: italic;
}
</style>
