<template>
  <fragment>
    <v-row>
      <v-col cols="12">
        <span class="text-body-1 font-weight-bold mb-10"
          >Bir parola belirleyiniz. Belirlediğiniz parolanızı sisteme giriş
          yaparken kullanacaksınız.
        </span>
        <v-text-field
          v-model.lazy="user.password"
          class="mt-4"
          :rules="[
            (v) => !!v || 'Parola alanı boş bırakılamaz',
            (v) =>
              v?.length >= 8 || 'Parola alanı en az 8 karakterden oluşmalıdır',
          ]"
          label="Parola"
          type="password"
          required
        ></v-text-field
      ></v-col>
      <v-col cols="12">
        <v-text-field
          v-model.lazy="user.password_confirmation"
          :rules="[
            (v) => !!v || 'Parola Tekrarı alanı boş bırakılamaz',
            (v) =>
              v?.length >= 8 ||
              'Parola Tekrarı alanı en az 8 karakterden oluşmalıdır',
            (v) =>
              v === user.password ||
              'Parola ve Parola Tekrarı alanları eşleşmiyor',
          ]"
          label="Parola Tekrar"
          type="password"
          required
        ></v-text-field
      ></v-col>
    </v-row>
  </fragment>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Password",
  props: {
    user: {
      type: Object,
      required: true,
    },
    isFormValid: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    prop: "user",
    event: "update:user",
  },
  watch: {
    user: {
      handler: function (val) {
        this.$emit("update:user", val);
      },
      deep: true,
    },
  },
});
</script>
