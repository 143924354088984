<template>
  <fragment>
    <v-row class="mt-2">
      <v-col cols="12">
        <v-text-field
          v-model="user.parent_information.name"
          hide-details="auto"
          label="Veli Ad Soyad"
          :rules="[(v) => !!v || 'Veli ad soyad alanı boş bırakılamaz']"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="user.parent_information.email"
          hide-details="auto"
          label="Veli E-Posta"
          :rules="[
            (v) => !!v || 'E-Posta alanı boş bırakılamaz',
            (v) =>
              /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) ||
              'Geçerli bir e-posta adresi giriniz',
          ]"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <SMPhoneSelectbox
          validatable
          :phone="user.parent_information.phone"
          :check-user-phone="user.phone"
          @update:phone="(phone) => (user.parent_information.phone = phone)"
        ></SMPhoneSelectbox>
      </v-col>
    </v-row>
  </fragment>
</template>

<script>
import SMPhoneSelectbox from "@/view/content/siliconmade/SM-Phone-Selectbox.vue";

export default {
  name: "Information",
  components: {
    SMPhoneSelectbox,
  },
  props: {
    user: {
      type: Object,
    },
    isFormValid: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    prop: "user",
    event: "update:user",
  },
  watch: {
    user: {
      handler: function (val) {
        this.$emit("update:user", val);
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
