var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('fragment',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-body-1 font-weight-bold mb-10"},[_vm._v("Bir parola belirleyiniz. Belirlediğiniz parolanızı sisteme giriş yaparken kullanacaksınız. ")]),_c('v-text-field',{staticClass:"mt-4",attrs:{"rules":[
          (v) => !!v || 'Parola alanı boş bırakılamaz',
          (v) =>
            v?.length >= 8 || 'Parola alanı en az 8 karakterden oluşmalıdır',
        ],"label":"Parola","type":"password","required":""},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":[
          (v) => !!v || 'Parola Tekrarı alanı boş bırakılamaz',
          (v) =>
            v?.length >= 8 ||
            'Parola Tekrarı alanı en az 8 karakterden oluşmalıdır',
          (v) =>
            v === _vm.user.password ||
            'Parola ve Parola Tekrarı alanları eşleşmiyor',
        ],"label":"Parola Tekrar","type":"password","required":""},model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }