var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fragment',[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"hide-details":"auto","label":"Ad Soyad","dense":"","rules":[
          (v) => !!v || 'Ad Soyad alanı boş bırakılamaz',
          (v) =>
            v?.length >= 3 ||
            'Ad Soyad alanı en az 3 karakterden oluşmalıdır',
        ],"required":""},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('SMEmailTextfield',{attrs:{"email":_vm.user.email ? _vm.user.email.email : '',"isFormValid":_vm.isFormValid,"user_id":_vm.user.id},on:{"update:email":(email) => {
            if (_vm.user.email === null) {
              _vm.user.email = { email };
            } else {
              _vm.user.email.email = email;
            }
          }}})],1)],1),_c('v-row',{staticClass:"mt-n5 justify-content-end align-items-end"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"d-block",staticStyle:{"top":"1px","position":"relative","color":"rgba(0, 0, 0, 0.6)"}},[_vm._v("Doğum Tarihi")]),_c('custom-date-picker',{attrs:{"value":_vm.user.birth_date,"isFormValid":_vm.isFormValid},on:{"update:value":(birth_date) => (_vm.user.birth_date = birth_date)}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.genders)?_c('v-select',{staticClass:"mb-1",attrs:{"items":_vm.genders,"item-value":"value","item-text":"translate","hide-details":"auto","rules":[(v) => !!v || 'Cinsiyet alanı boş bırakılamaz'],"label":"Cinsiyet"},model:{value:(_vm.user.gender),callback:function ($$v) {_vm.$set(_vm.user, "gender", $$v)},expression:"user.gender"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-2",attrs:{"cols":"6"}},[(!_vm.notCitizen)?_c('v-text-field',{attrs:{"hide-details":"auto","label":"Kimlik Numarası","dense":"","maxLength":"11","rules":[
          (v) => !!v || 'Kimlik Numarası alanı boş bırakılamaz',
          (v) => v?.length === 11 || 'Kimlik Numarası 11 haneli olmalıdır',
          (v) =>
            /^\d+$/.test(v) ||
            'Kimlik Numarası sadece rakamlardan oluşmalıdır',
          (v) => _vm.identityVerification(v),
        ]},model:{value:(_vm.user.identity),callback:function ($$v) {_vm.$set(_vm.user, "identity", $$v)},expression:"user.identity"}}):_c('v-text-field',{attrs:{"hide-details":"auto","label":"Pasaport Numarası","dense":"","rules":[(v) => !!v || 'Pasaport Numarası alanı boş bırakılamaz']},model:{value:(_vm.user.passport_number),callback:function ($$v) {_vm.$set(_vm.user, "passport_number", $$v)},expression:"user.passport_number"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-switch',{attrs:{"label":"TC Vatandaşı Değilim","color":"primary"},model:{value:(_vm.notCitizen),callback:function ($$v) {_vm.notCitizen=$$v},expression:"notCitizen"}})],1)],1),_c('v-row',{staticClass:"mt-n5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('SMPhoneSelectbox',{attrs:{"validatable":"","validate-id":_vm.user.id,"phone":_vm.user.phone},on:{"update:phone":(phone) => (_vm.user.phone = phone)}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.isRegisterForm)?_c('span',{staticClass:"text-body-1 font-weight-bold"},[_vm._v("Lütfen güncel bilgilerinizi giriniz! Kişisel verileriniz "),_c('a',{staticClass:"text-blue",attrs:{"href":`${_vm.baseUrl}/storage/statics/Aydinlatma_Metni.pdf`,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Aydınlatma Metni ")]),_vm._v("kapsamında işlenmektedir.")]):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }