<template>
  <fragment>
    <v-row class="mt-2">
      <v-col cols="12">
        <v-select
          :items="status"
          item-text="translate"
          item-value="value"
          v-model="selected"
          @change="(val) => (user.work_information.status = val)"
          label="Çalışma Durumu"
          :rules="[(v) => !!v || 'Statü alanı boş bırakılamaz']"
          hide-details="auto"
          required
        />
      </v-col>
    </v-row>
    <fragment v-if="isEmployee">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="user.work_information.company"
            hide-details="auto"
            label="Çalıştığı Kurum"
            :rules="[(v) => !!v || 'Çalıştığı Kurum alanı boş bırakılamaz']"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="user.work_information.position"
            hide-details="auto"
            label="Pozisyon"
            :rules="[(v) => !!v || 'Pozisyon alanı boş bırakılamaz']"
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </fragment>
    <fragment v-else-if="isStudent">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="user.work_information.school"
            hide-details="auto"
            label="Okul"
            :rules="[(v) => !!v || 'Okul alanı boş bırakılamaz']"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="user.work_information.field_of_study"
            hide-details="auto"
            label="Bölüm"
            :rules="[(v) => !!v || 'Bölüm alanı boş bırakılamaz']"
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </fragment>
  </fragment>
</template>

<script>
import AxiosInstance from "@/core/services/axios.service";

export default {
  name: "Information",
  props: {
    user: {
      type: Object,
    },
    isFormValid: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    prop: "user",
    event: "update:user",
  },
  data() {
    return {
      status: [],
      selected: null,
    };
  },
  created() {
    this.getWorkInformations();
  },
  methods: {
    getWorkInformations() {
      AxiosInstance.get("/enums", {
        params: {
          enum: "work_information_status_enum",
        },
      }).then((response) => {
        this.status = response.data;
        if (
          this.user.work_information.status === undefined ||
          this.user.work_information.status === null
        ) {
          this.user.work_information.status = this.status[1].value;
          this.selected = this.user.work_information.status;
        } else {
          this.selected = this.user.work_information.status;
        }
      });
    },
  },
  watch: {
    isEmployee() {
      if (this.isEmployee) {
        this.user.work_information.school = null;
        this.user.work_information.field_of_study = null;
      }
    },
    isStudent() {
      if (this.isStudent) {
        this.user.work_information.company = null;
        this.user.work_information.position = null;
      }
    },
    isUnemployed() {
      if (this.isUnemployed) {
        this.user.work_information.company = null;
        this.user.work_information.position = null;
        this.user.work_information.school = null;
        this.user.work_information.field_of_study = null;
      }
    },
    user: {
      handler: function (val) {
        this.$emit("update:user", val);
      },
      deep: true,
    },
  },
  computed: {
    isEmployee() {
      return this.selected === "employee" ? true : false;
    },
    isStudent() {
      return this.selected === "student" ? true : false;
    },
    isUnemployed() {
      return this.selected === "unemployed" ? true : false;
    },
  },
};
</script>

<style scoped></style>
