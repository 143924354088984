<template>
  <v-container
    class="d-flex flex-column justify-content-center align-items-center"
    fill-height
  >
    <v-stepper
      v-model="activeFormIndex"
      class="register-form"
      height="auto"
      :min-height="displayMobile ? '65vh' : '500px'"
    >
      <v-stepper-header v-if="!displayMobile">
        <v-stepper-step
          :complete="activeFormIndex > index"
          :step="index + 1"
          :key="index"
          v-for="(form, index) in activeForms"
          >{{ form.name }} <small v-if="form.isOptional">Opsiyonel</small>
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content
          :step="index + 1"
          :key="index"
          v-for="(form, index) in activeForms"
          owerflow-y="auto"
        >
          <div v-if="displayMobile" class="d-flex justify-content-between">
            <h1 class="align-self-center">{{ form.name }}</h1>
            <v-progress-circular
              :rotate="-90"
              :size="70"
              :width="7"
              :value="(activeFormIndex / activeForms.length) * 100"
              color="primary"
            >
              {{ activeFormIndex }} / {{ activeForms.length }}
            </v-progress-circular>
            <!-- <p v-if="form.isOptional">(Opsiyonel)</p> -->
          </div>
          <v-divider v-if="displayMobile" />
          <v-form ref="form" lazy-validation v-model="isFormValid">
            <component
              :is="form.component"
              :isFormValid="isFormValid"
              :key="index"
              v-model="user"
              :isRegisterForm="true"
            />
          </v-form>
          <div
            class="d-flex mt-10"
            style="align-items: self-end"
            :class="
              activeFormIndex === 1
                ? 'justify-content-end'
                : 'justify-content-between'
            "
          >
            <v-btn
              color="primary"
              :class="activeFormIndex === 1 ? 'justify-self-end' : ''"
              @click="changeForm(activeFormIndex - 1)"
              v-if="activeFormIndex > 1"
              >Geri</v-btn
            >
            <v-btn
              color="primary"
              @click="changeForm(activeFormIndex + 1)"
              :disabled="!isFormValid"
              v-if="activeFormIndex < activeForms.length"
              >İleri</v-btn
            >
            <v-btn
              color="success"
              @click="register"
              :loading="loading"
              :disabled="!isFormValid"
              v-if="activeFormIndex === activeForms.length"
              >Kullanıcı Kaydını Tamamla</v-btn
            >
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import Information from "@/view/content/users/informations/Information";
import Address from "@/view/content/users/informations/Address";
import WorkInformation from "@/view/content/users/informations/WorkInformation";
import Agreements from "@/view/content/users/informations/Agreements";
import ParentInformation from "@/view/content/users/informations/ParentInformation";
import Passwords from "@/view/content/users/informations/Passwords";
import ParentAddress from "@/view/content/users/informations/ParentAddress";

import { checkUserAgeUnder18 } from "@/core/helper.js";

import { isEmptyObject } from "jquery";

export default {
  name: "RegisterForm",
  components: {
    Address,
    Information,
    WorkInformation,
    Agreements,
    ParentInformation,
    Passwords,
    ParentAddress,
  },
  data() {
    return {
      isFormValid: true,
      user: JSON.parse(window.localStorage.getItem("user")) || {},
      activeFormIndex: parseInt(window.localStorage.getItem("activeForm")) || 1,
      forms: [
        {
          name: "Kişisel Bilgiler",
          component: "Information",
          isOptional: false,
        },
        {
          name: "Parola",
          component: "Passwords",
          isOptional: false,
        },
        {
          name: "Adres Bilgileri",
          component: "Address",
          isOptional: false,
        },
        {
          name: "Aile Bilgileri",
          component: "ParentInformation",
          isOptional: false,
        },
        {
          name: "Aile Adres Bilgileri",
          component: "ParentAddress",
          isOptional: false,
        },
        {
          name: "Çalışma Durumu",
          component: "WorkInformation",
          isOptional: false,
        },
        {
          name: "İzinler",
          component: "Agreements",
          isOptional: false,
        },
      ],
      loader: null,
      loading: false,
    };
  },
  methods: {
    checkUserAgeUnder18,
    changeForm(index) {
      const valid = index === 1 ? true : this.$refs.form[index - 2].validate();
      if (!valid) {
        this.isFormValid = false;
        return;
      }
      window.localStorage.setItem("activeForm", index);
      const user = JSON.stringify(this.user);
      window.localStorage.setItem("user", user);
      this.activeFormIndex = index;
      this.isFormValid = true;
    },
    async register() {
      const valid = this.$refs.form[this.activeFormIndex - 1].validate();
      if (!valid) {
        this.isFormValid = false;
        return;
      }
      this.loader = "loading";
      this.user.from_register_page = true;
      this.user.isCitizen = this.user.identity === null ? false : true;
      if (isEmptyObject(this.user?.parent_information?.address)) {
        delete this.user.parent_information;
      }
      try {
        await this.updateUser();
        await this.$router.push({ name: "register-success" });
      } catch (err) {
        this.$toast.error(err[0].detail, {
          duration: 2000,
          position: "bottom-right",
        });
      } finally {
        this[this.loader] = false;
        this.loader = null;
      }
    },
    updateUser() {
      return new Promise((resolve, reject) => {
        this.$apiService
          .post("/auth/register", this.user)
          .then(({ response }) => {
            resolve(response);
          })
          .catch(({ response }) => {
            reject(response.data.errors);
          });
      });
    },
  },
  computed: {
    activeForm() {
      return this.forms[this.activeFormIndex].component;
    },
    displayMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    activeForms() {
      if (!this.checkUserAgeUnder18(this.user.birth_date)) {
        return this.forms.filter(
          (form) =>
            form.name !== "Aile Bilgileri" &&
            form.name !== "Aile Adres Bilgileri"
        );
      }

      return this.forms;
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
    },
    "user.birth_date": {
      handler(oldV, newV) {
        if (!this.checkUserAgeUnder18(newV)) {
          this.user.parent_information = {};
          this.user.parent_information.address = {};
        }

        this.forms.filter(
          (form) =>
            form.name !== "Aile Bilgileri" &&
            form.name !== "Aile Adres Bilgileri"
        );
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.register-form {
  background-color: #fff;
  border-radius: 2.6rem;
  padding: 1.5rem;
  /* height: calc(100% - 5vh); */
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}
</style>
