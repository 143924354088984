<template>
  <div>
    <v-text-field
      v-model="email"
      dense
      label="E-Posta"
      validate-on-blur
      @input="(v) => checkEmailIsUnique(v)"
    >
    </v-text-field>
    <v-progress-linear
      v-if="emailChecking"
      indeterminate
      color="blue-grey"
    ></v-progress-linear>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    user_id: {
      type: Number,
      default: null,
    },
    email: {
      type: String,
      default: null,
    },
    isFormValid: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.email) this.checkEmailIsUnique(this.email);
  },
  data() {
    return {
      emailChecking: false,
      checkTimeout: null,
      isEmailUnique: true,
    };
  },
  methods: {
    async checkEmailIsUnique(value = this.email) {
      this.isEmailUnique = true;
      if (!value) return true;
      if (this.checkTimeout) clearTimeout(this.checkTimeout);
      this.checkTimeout = setTimeout(async () => {
        this.emailChecking = true;
        try {
          await this.$apiService.post("/email/verify", {
            email: value,
            user_id: this.user_id,
          });
          this.isEmailUnique = true;
        } catch (error) {
          this.isEmailUnique = false;
        } finally {
          this.emailChecking = false;
          this.$emit("update:email", this.email);
        }
      }, 1500);
      return;
    },
  },
  computed: {
    emailRules() {
      const rules = [];
      if (!this.isEmailUnique)
        rules.push(() => "Bu e-posta adresi kullanılmaktadır");
      return rules;
    },
  },
});
</script>
