var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{staticStyle:{"height":"80px"},attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"rules":[
          (v) =>
            !!v || 'Devam Etmek İçin Aydınlatma Metnini Kabul Etmelisiniz.',
        ],"color":"primary"},on:{"change":_vm.onCheckUserPrivacy},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('a',{staticClass:"text-blue",attrs:{"href":`${_vm.baseUrl}/storage/statics/Aydinlatma_Metni.pdf`,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Aydınlatma Metnini ")]),_vm._v(" Okudum ve Anladım. ")])]},proxy:true}]),model:{value:(_vm.user.personal_data_protection),callback:function ($$v) {_vm.$set(_vm.user, "personal_data_protection", $$v)},expression:"user.personal_data_protection"}})],1),_c('v-col',{staticStyle:{"height":"80px"},attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"rules":[
          (v) =>
            !!v || 'Devam Etmek İçin Açık Rıza Metnini Kabul Etmelisiniz.',
        ],"color":"primary"},on:{"change":_vm.onCheckUserAggrement,"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('a',{staticClass:"text-blue",attrs:{"href":`${_vm.baseUrl}/storage/statics/Acik_Riza_Metni.pdf`,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("Açık Rıza Metnini")]),_vm._v(" Okudum ve Kabul Ediyorum. ")])]},proxy:true}]),model:{value:(_vm.user.clarification),callback:function ($$v) {_vm.$set(_vm.user, "clarification", $$v)},expression:"user.clarification"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"id":"inputArea","color":"primary"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Siliconmade Academy Eğitim A.Ş.'nin Ticari Elektronik İleti Faaliyetleri Kapsamında İşleyeceği Kişisel Verilere İlişkin "),_c('a',{staticClass:"text-blue",attrs:{"href":`${_vm.baseUrl}/storage/statics/Aydinlatma_Metni.pdf`,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Aydınlatma Metnini ")]),_vm._v(" Okudum ve Anladım. Siliconmade Academy'nin özel promosyon ve kampanya duyurularını telefon konuşması, e-posta ve sms yoluyla almak istiyorum. ")])]},proxy:true}]),model:{value:(_vm.user.commercial_message),callback:function ($$v) {_vm.$set(_vm.user, "commercial_message", $$v)},expression:"user.commercial_message"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }