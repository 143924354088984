<template>
  <div>
    <v-row>
      <v-col cols="12" style="height: 80px">
        <v-checkbox
          @change="onCheckUserPrivacy"
          v-model="user.personal_data_protection"
          :rules="[
            (v) =>
              !!v || 'Devam Etmek İçin Aydınlatma Metnini Kabul Etmelisiniz.',
          ]"
          color="primary"
        >
          <template v-slot:label>
            <div>
              <a
                :href="`${baseUrl}/storage/statics/Aydinlatma_Metni.pdf`"
                target="_blank"
                class="text-blue"
                @click.stop
              >
                Aydınlatma Metnini
              </a>
              Okudum ve Anladım.
            </div>
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="12" style="height: 80px">
        <v-checkbox
          @change="onCheckUserAggrement"
          v-model="user.clarification"
          :rules="[
            (v) =>
              !!v || 'Devam Etmek İçin Açık Rıza Metnini Kabul Etmelisiniz.',
          ]"
          color="primary"
          @click.stop
        >
          <template v-slot:label>
            <div>
              <a
                :href="`${baseUrl}/storage/statics/Acik_Riza_Metni.pdf`"
                target="_blank"
                class="text-blue"
                @click.stop
                >Açık Rıza Metnini</a
              >
              Okudum ve Kabul Ediyorum.
            </div>
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="12">
        <v-checkbox
          id="inputArea"
          v-model="user.commercial_message"
          color="primary"
        >
          <template v-slot:label>
            <div>
              Siliconmade Academy Eğitim A.Ş.'nin Ticari Elektronik İleti
              Faaliyetleri Kapsamında İşleyeceği Kişisel Verilere İlişkin
              <a
                :href="`${baseUrl}/storage/statics/Aydinlatma_Metni.pdf`"
                target="_blank"
                class="text-blue"
                @click.stop
              >
                Aydınlatma Metnini
              </a>
              Okudum ve Anladım. Siliconmade Academy'nin özel promosyon ve
              kampanya duyurularını telefon konuşması, e-posta ve sms yoluyla
              almak istiyorum.
            </div>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Agreements",
  components: {},
  props: {
    user: {
      type: Object,
    },
    isFormValid: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    prop: "user",
    event: "update:user",
  },
  data() {
    return {
      showUserAggrement: false,
      showUserPrivacy: false,
    };
  },
  methods: {
    agreementResultFromKVKK(result) {
      this.user.clarification = result.isAgreed;
      this.showUserAggrement = false;
    },
    aggrementResultFromPrivacyPolicy(result) {
      this.user.personal_data_protection = result.isAgreed;
      this.showUserPrivacy = false;
    },
    onCheckUserAggrement() {
      if (this.user.clarification) {
        this.showUserAggrement = true;
      }
    },
    onCheckUserPrivacy() {
      if (this.user.personal_data_protection) {
        this.showUserPrivacy = true;
      }
    },
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_BASE_URL;
    },
  },
  watch: {
    user: {
      handler: function (val) {
        this.$emit("update:user", val);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input__control .v-input__slot {
  display: flex;
  align-items: flex-start;
}
</style>
