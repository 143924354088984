<template>
  <fragment>
    <v-row class="mt-2">
      <v-col cols="12">
        <v-text-field
          v-model.lazy.trim="user.name"
          hide-details="auto"
          label="Ad Soyad"
          dense
          :rules="[
            (v) => !!v || 'Ad Soyad alanı boş bırakılamaz',
            (v) =>
              v?.length >= 3 ||
              'Ad Soyad alanı en az 3 karakterden oluşmalıdır',
          ]"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <SMEmailTextfield
          :email="user.email ? user.email.email : ''"
          @update:email="
            (email) => {
              if (user.email === null) {
                user.email = { email };
              } else {
                user.email.email = email;
              }
            }
          "
          :isFormValid="isFormValid"
          :user_id="user.id"
        ></SMEmailTextfield>
      </v-col>
    </v-row>
    <v-row class="mt-n5 justify-content-end align-items-end">
      <v-col cols="12" md="6">
        <span
          class="d-block"
          style="top: 1px; position: relative; color: rgba(0, 0, 0, 0.6)"
          >Doğum Tarihi</span
        >
        <custom-date-picker
          :value="user.birth_date"
          @update:value="(birth_date) => (user.birth_date = birth_date)"
          :isFormValid="isFormValid"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          class="mb-1"
          v-if="genders"
          :items="genders"
          item-value="value"
          item-text="translate"
          hide-details="auto"
          :rules="[(v) => !!v || 'Cinsiyet alanı boş bırakılamaz']"
          v-model.lazy="user.gender"
          label="Cinsiyet"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="mt-2">
        <v-text-field
          v-model.lazy="user.identity"
          v-if="!notCitizen"
          hide-details="auto"
          label="Kimlik Numarası"
          dense
          maxLength="11"
          :rules="[
            (v) => !!v || 'Kimlik Numarası alanı boş bırakılamaz',
            (v) => v?.length === 11 || 'Kimlik Numarası 11 haneli olmalıdır',
            (v) =>
              /^\d+$/.test(v) ||
              'Kimlik Numarası sadece rakamlardan oluşmalıdır',
            (v) => identityVerification(v),
          ]"
        />
        <v-text-field
          v-model.lazy="user.passport_number"
          v-else
          hide-details="auto"
          label="Pasaport Numarası"
          dense
          :rules="[(v) => !!v || 'Pasaport Numarası alanı boş bırakılamaz']"
        />
      </v-col>
      <v-col cols="6">
        <v-switch
          v-model="notCitizen"
          label="TC Vatandaşı Değilim"
          color="primary"
        >
        </v-switch>
      </v-col>
    </v-row>
    <v-row class="mt-n5">
      <v-col cols="12">
        <SMPhoneSelectbox
          validatable
          :validate-id="user.id"
          :phone="user.phone"
          @update:phone="(phone) => (user.phone = phone)"
        ></SMPhoneSelectbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <span v-if="isRegisterForm" class="text-body-1 font-weight-bold"
          >Lütfen güncel bilgilerinizi giriniz! Kişisel verileriniz
          <a
            :href="`${baseUrl}/storage/statics/Aydinlatma_Metni.pdf`"
            target="_blank"
            class="text-blue"
            @click.stop
          >
            Aydınlatma Metni </a
          >kapsamında işlenmektedir.</span
        >
      </v-col>
    </v-row>
  </fragment>
</template>

<script>
import CustomDatePicker from "@/view/content/components/CustomDatePicker.vue";
import SMPhoneSelectbox from "@/view/content/siliconmade/SM-Phone-Selectbox.vue";
import SMEmailTextfield from "@/view/content/siliconmade/SM-Email-Textfield.vue";

export default {
  components: {
    SMPhoneSelectbox,
    CustomDatePicker,
    SMEmailTextfield,
  },
  name: "Information",
  data() {
    return {
      genders: [],
      notCitizen: this.isUserCitizen,
    };
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    isFormValid: {
      type: Boolean,
      default: true,
    },
    isRegisterForm: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "user",
    event: "update:user",
  },
  methods: {
    identityVerification(value) {
      // Uzunluk sorgulaması
      if (value?.length !== 11) {
        return "TC Numarası 11 haneden küçük olamaz!";
      }

      // İçerik sorgulaması
      let tc = Array.from(value);
      let isInteger = true;

      tc.forEach((item) => {
        if (isNaN(item)) {
          isInteger = false;
        }
      });

      if (!isInteger) {
        return "TC Numarasında bulunan karakterlerden bazıları geçersiz değerler içeriyor!";
      }

      // İlk hanesi 0 olmamalı
      if (value.startsWith("0")) {
        return "TC Numarası 0 (sıfır) ile başlayamaz!";
      }

      // Sayısal doğrulama
      let isValid = false;
      // Çift Sayılar
      let evenNumberSum = 0;
      // Tek Sayılar
      let oddNumberSum = 0;
      let firstTenNumberSum = 0;

      for (let i = 0; i < value.length; i++) {
        let item = parseInt(tc[i]);

        if ((i + 1) % 2 === 0) {
          if (i != 9) {
            evenNumberSum += item;
          }
        } else {
          if (i != 10) {
            oddNumberSum += item;
          }
        }
      }

      for (let i = 0; i < 10; i++) {
        firstTenNumberSum += parseInt(tc[i]);
      }

      if (
        parseInt(tc[9]) === (oddNumberSum * 7 - evenNumberSum) % 10 &&
        parseInt(tc[10]) === firstTenNumberSum % 10
      ) {
        isValid = true;
      }

      if (!isValid) {
        return "Girilen TC numarası geçersiz bir numaradır!";
      }

      return true;
    },
  },
  mounted() {
    this.$apiService
      .get("/enums", { params: { enum: "gender_enum" } })
      .then((response) => {
        this.genders = response.data;
      });
  },
  watch: {
    user: {
      handler: function (val) {
        this.$emit("update:user", val);
      },
      deep: true,
    },
    notCitizen: {
      handler: function (val) {
        if (val) {
          this.user.identity = null;
        } else {
          this.user.passport_number = null;
        }
      },
    },
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_BASE_URL;
    },
    isUserCitizen() {
      if (this.user.register_token === null) {
        if (this.user.identity === null) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
  },
};
</script>
